import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './assets/scss/App.scss';

import Header from './views/Header';
import Homepage from './views/Homepage';
import Auction from './views/Auction';
import Backoffice from './views/Backoffice';
import Footer from './views/Footer';

function App() {
  if (window.location.pathname === '/backoffice') {
    return <Backoffice />
  }
  return (
    <div id="app">
      <Header />
      <Router>
        <Switch>
          <Route exact path="/backoffice">
            <Backoffice />
          </Route>
          <Route exact path="/leilao">
            <Auction />
          </Route>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
      <Footer />
    </div >
  );
}

export default App;