import React, { useEffect, useState } from 'react';
import { Carousel, List, Modal, Row, Col, Button } from 'antd';
import axios from 'axios';

import data from '../assets/json/auctions21.json';
import '../assets/scss/Auction.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

function Auction() {
  const [visibleBio, setVisibleBio] = useState(false);
  const [visibleSeller, setVisibleSeller] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [selectedAuction, setSelectedAuction] = useState(null);

  useEffect(() => {
    axios.get("https://ajudaris-api.herokuapp.com/api/auctions")
      .then(data => {
        const prices = data.data.map(product => {
          return {
            key: +product.name,
            price: product.price
          }
        })
        setPrices(prices);
      })
      .catch(error => {
        console.warn(error);
      })
  }, []);

  useEffect(() => {
    async function fetchAuctions() {
      const auctions = await Promise.all(
        data.auctions
          .sort((auction1, auction2) => auction1.sellerName.localeCompare(auction2.sellerName))
          .map(async (auction) => {
            let bioText = "";
            let sellerBioText = ""
            if (auction.productBio) {
              await axios.get(`./auctions/${auction.sellerName}/${auction.productBio}`)
                .then(bio => {
                  bioText = bio.data;
                })
                .catch((error) => console.warn(`${auction.sellerName} + ${error}`));
            }

            if (auction.sellerBio) {
              await axios.get(`./auctions/${auction.sellerName}/${auction.sellerBio}`)
                .then(bio => {
                  sellerBioText = bio.data;
                })
                .catch((error) => console.warn(`${auction.sellerName} + ${error}`));;
            }

            return {
              key: auction.key,
              productImages: auction.productImages,
              productName: auction.productName,
              productBio: bioText,
              sellerImage: auction.sellerImage,
              sellerName: auction.sellerName,
              sellerBio: sellerBioText,
              price: auction.price,
              link: auction.link
            }
          }));
      setAuctions(auctions);
    }
    fetchAuctions();
  }, [visibleBio]);

  function showModal(auction) {
    setVisibleBio(true);
    setSelectedAuction(auction);
  }

  const hideModal = () => {
    setVisibleBio(false);
    setSelectedAuction(null);
  }

  function showSeller(auction) {
    setVisibleSeller(true);
    setSelectedAuction(auction);
  }

  const hideSeller = () => {
    setVisibleSeller(false);
    setSelectedAuction(null);
  }

  return (
    // https://forms.gle/r6gW9gXmHhNqYPxNA
    <div id="auction">
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={auctions}
        renderItem={auction => (
          <div>
            <Carousel autoplay>
              {
                auction.productImages.map((image, index) => (
                  <div key={index}>
                    <img className="product-image" loading="lazy"
                      src={`./auctions/${auction.sellerName}/${image}`} alt={auction.productName} onClick={() => showModal(auction)} />
                  </div>
                ))
              }
            </Carousel>
            <p className="product-bio">{auction.productName}</p>
            <Row className="seller-info">
              <Col span={8}>
                <img className="seller-image" loading="lazy" src={`./auctions/${auction.sellerName}/${auction.sellerImage}`} alt={auction.sellerName}
                  onClick={() => showSeller(auction)} onError={(e) => { e.target.src = `./auctions/profile-picture-error.jpg` }} />
                <p><b>{auction.sellerName}</b></p>
              </Col>
              <Col className="pricing" span={8}>
                <p>
                  <b>Preço Base</b>
                  <br />
                  €{auction.price.toFixed(2)}
                </p>
                <p>
                  <b>Preço da Licitação</b>
                  <br />
                  €{prices.find(price => price.key === auction.key).price.toFixed(2)}
                </p>
              </Col>
              <Col className="auction-btn-wrapper" span={8}>
                <Button className="auction-btn" type="primary" size="large" href="https://forms.gle/xzadRzW9DMePyZkm7" target="_blank">
                  Leilão <FontAwesomeIcon className="money-check" icon={faWallet} />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      />
      <div id="donations">
        <h2>
          Aproveitamos ainda para agradecer a quem doou donativos!
          
        </h2>
        <Row>
          <Col xs={24} md={24}>
            <img className="seller-image seller-image-override" src={`./auctions/Isabel Medina/Isabel Medina.jpg`} alt="Isabel Medina" />
            <h2>Isabel Medina</h2>
          </Col>
        </Row>
      </div>
      <Modal
        visible={visibleBio}
        onCancel={hideModal}
        footer={null}>
        {
          selectedAuction && selectedAuction.productBio && <p dangerouslySetInnerHTML={{ __html: selectedAuction.productBio }}></p>
        }
        {
          selectedAuction && !selectedAuction.productBio && <p>Descrição indisponível.</p>
        }
      </Modal>
      <Modal
        visible={visibleSeller}
        onCancel={hideSeller}
        footer={null}>
        {
          selectedAuction && selectedAuction.sellerBio && <p dangerouslySetInnerHTML={{ __html: selectedAuction.sellerBio }}></p>
        }
        {
          selectedAuction && !selectedAuction.sellerBio && <p>Descrição indisponível.</p>
        }
      </Modal>
    </div>
  );
}
export default Auction;