import '../assets/scss/Footer.scss'
import Ajudaris from '../assets/img/ajudaris-logo.png';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <div id="footer-wrapper">
            <div id="footer">
                <Row>
                    <Col xs={24} md={8}>
                        <img id="footer-logo" src={Ajudaris} alt="Ajudaris" />
                    </Col>
                    <Col xs={24} md={8}>
                        <h2><b>Contactos</b></h2>
                        <p><b>Morada: </b>Rua de S. Jorge nº 144 - Bairro da Azenha
                        <br />
                        4200-484 Porto (Freguesia de Paranhos)
                        </p>
                        <p><b>Nº Telefone | Fax: </b>222 013 159</p>
                        <p><b>Correio Eletrónico: </b><a href="mailto:geral@ajudaris.org">geral@ajudaris.org</a></p>
                    </Col>
                    <Col xs={24} md={8}>
                        <h2><b>Redes Sociais</b></h2>
                        <a href="https://www.facebook.com/associacaoajudaris/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon id="fb-btn" icon={faFacebook} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCqhfnTn4RTSXEzuQ998jLcQ" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon id="yt-btn" icon={faYoutube} />
                        </a>
                        <a href="https://www.instagram.com/ajudaris/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon id="ig-btn" icon={faInstagram} />
                        </a>
                        <a href="https://www.linkedin.com/company/ajudaris/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon id="li-btn" icon={faLinkedin} />
                        </a>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Footer;