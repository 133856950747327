import '../assets/scss/Homepage.scss';
import Poster from '../assets/img/main-poster.png';
import Arriscar from '../assets/img/a(r)riscar.jpg';
import Compartilharte from '../assets/img/compartilhArt.jpg';
import Historias from '../assets/img/historias-ajudaris.png';
import VozAlta from '../assets/img/VozAlta.png';

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Statistic, Row, Col, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { Countdown } = Statistic;
const deadline = new Date(1640217600000);

function Homepage() {
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    axios.get("https://ajudaris-api.herokuapp.com/api/auctions/money-raised")
      .then(data => {
        setMaxPrice(data.data);
      })
      .catch(error => {
        console.warn(error);
      })
  }, []);

  return (
    <div id="homepage">
      <div id="leilao" className="red-section">
        <div id="auction" className="content">
          <h1>Leilão Solidário</h1>
          <a href="/leilao">
            <img id="poster" src={Poster} alt="Leilão Solidário" />
          </a>
          <Countdown value={deadline} format="HH:mm:ss"></Countdown>
          <h2>Total angariado: €{maxPrice}</h2>
          <Button id="auction-btn" type="primary" size="large" href="/leilao">Leilão Solidário <RightOutlined /></Button>
        </div>
      </div>
      <div id="sobre-nos" className="white-section">
        <div id="about-us" className="content">
          <h1>Quem Somos</h1>
          <p>A <b>AJUDARIS</b> é uma associação particular de carácter social e humanitária de âmbito nacional,
            sem fins lucrativos, que luta diariamente contra a fome, pobreza e a exclusão social.</p>
          <p>Foi fundada em
            Julho de 2008 e considerada de Utilidade Pública desde Outubro do mesmo ano. A <b>AJUDARIS</b> figura
            assim como uma IPSS – Instituição Particular de Solidariedade Social.</p>
          <p>
            Foi fundada por um grupo
            de voluntários que, pontualmente, faziam Intervenção Social, no terreno, junto de populações mais
            carenciadas do Grande Porto. Perante os constantes apelos da comunidade apoiada, surgiu a
            necessidade de criar respostas mais regulares, organizadas e eficientes que visam o desenvolvimento
            de um trabalho multidisciplinar.
          </p>

          <h2>Objetivos</h2>
          <ul>
            <li>Promover diversas actividades de carácter social e humanitário que contribuam para a inclusão e integração social;</li>
            <li>Auxiliar no combate à pobreza persistente e às novas formas de exclusão social, através da garantia dos direitos básicos de cidadania, visando auxiliar os grupos mais desfavorecidos;</li>
            <li>Articular e complementar o trabalho social com as várias Entidades Públicas e Privadas;</li>
            <li>Criar Equipamentos Sociais e Redes de Cooperação que possibilitem um apoio individual tendo em conta as problemáticas e idiossincrasias de cada Utente.</li>
          </ul>

          <h2>Missão</h2>
          <p>Estimular e promover a qualidade de vida dos seus utentes, visando
            desenvolver a autonomia, combater a solidão, bem como, promover a interação destes com as
            suas famílias, com grupos sociais de faixas etárias heterogéneas, instituições e comunidade
            em geral.</p>

          <h2>Valores e Princípios</h2>
          <ul>
            <li> Humanização e personalização no atendimento, na ação e nos serviços prestados;</li>
            <li>Respeito pela individualidade, privacidade, intimidade e direito dos utentes e colaboradores;</li>
            <li>Espírito de acolhimento e de solidariedade;</li>
            <li>Envolvimento, dedicação e compromisso em todo trabalho a desempenhar.</li>
          </ul>
        </div>
      </div>
      <div id="como-ajudar" className="red-section">
        <div id="how-to-help" className="content">
          <h1>Como Ajudar</h1>
          <Row>
            <Col xs={24} md={12}>
              <h3>Doar Alimentos</h3>
              <p>
                A Ajudaris promove mensalmente a distribuição de cabazes alimentares a famílias carenciadas
                já sinalizadas. Contamos com a sua ajuda! Poderá efectuar a entrega de bens junto da sede da
                associação na Rua de S. Jorge nº 144 – Bairro da Azenha, 4200-484 Porto (Freguesia de Paranhos).‍
                Bens Alimentares Prioritários: ‍Leite, Arroz, Massa, Enlatados, Cereais, Papas, Azeite, Óleo.
              </p>
            </Col>
            <Col xs={24} md={12}>
              <h3>Cartão Amigo</h3>
              <p>
                Seja Amigo da Ajudaris, a partir de 10€ por ano! Para se tornar amigo “Ajudaris”, basta preencher
                a ficha de inscrição disponível e, de seguida, proceder à dádiva de donativo. Com a subscrição do
                Cartão Amigo Ajudaris você estará a colaborar diretamente no desenvolvimento dos projetos da associação.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <h3> 0,5% do seu IRS </h3>
              <p>
                Aceda à sua área pessoal no Portal das Finanças &gt; Clique em “IRS” &gt;
                No menu lateral esquerdo, clique em “Comunicar Entidade a Consignar IRS/IVA” &gt;
                Procure pelo NIF do Ajudaris - Associação de Solidariedade Social - 508682703 – selecione Ajudaris - Associação de Solidariedade Social &gt;
                Clique em “submeter".
              </p>
            </Col>
            <Col xs={24} md={12}>
              <h3>Voluntariado</h3>
              <p>
                Faça parte da Equipa Ajudaris! Apenas é possível desenvolver e alargar o apoio aos mais necessitados e
                desprotegidos, graças à generosa colaboração e apoio de todos os “Amigos” e Voluntários que acreditam no
                abalho desenvolvido, dedicando um pouco do seu tempo disponível, aos outros. Ao seres voluntário da
                Ajudaris poderás participar nos diferentes projectos e ainda colaborar noutros eventos.
                Inscreve-te como voluntário!
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <h3>Loja dos Afetos</h3>
              <p>
                Os artigos postos à venda na loja dos Afetos da Ajudaris irão contribuir para sorrisos de crianças,
                jovens e adultos carenciados!
              </p>
            </Col>
            <Col xs={24} md={12}>
              <h3>Donativo</h3>
              <p>
                Todos os donativos doados à Ajudaris, sejam monetários e/ou em géneros, estão abrangidos pelo
                Estatuto dos Benefícios Fiscais (Lei do Mecenato – dedutível em IRS/IRC). Poderá efectuar o seu Donativo por:
              </p>
              <p><b>Transferência Bancária</b></p>
              <p>
                IBAN: PT50 0035 0651 0053 2374 9305 9 (CGD)
                <br />
                IBAN: PT50 0045 1352 4024 4731 1244 0 (C.A)
                <br />
                IBAN: PT50 0010 0000 4757 0790 0016 5 (BPI)
                <br />
                IBAN: PT50 0036 0407 9910 6003 8308 7 (Montepio)
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div id="projetos" className="white-section">
        <div className="content">
          <h1>Projetos</h1>
          <div id="projects">
            <div>
              <img className="left-side" src={Arriscar} alt="Leilão Solidário" />
              <div>
                <h2>A(r)riscar</h2>
                <p>
                  Existe desde 2016/2017, envolvendo mais de 1500 jovens, cerca de 100 concelhos, de vários pontos do país e tem como objetivo primordial
                  despertar os jovens para a importância de temas atuais através da ilustração!
                </p>
                <p>
                  Público-Alvo: 3.º ciclo e Secundário
                </p>
                <p>
                  Tema 2021-2022: Pintura e Pintores Portugueses
                </p>
              </div>
            </div>

            <div>
              <div>
                <h2>CompartilhArte</h2>
                <p>
                  Pretende despertar a consciência das crianças para a partilha de afetos e emoções, promover a arte e as expressões artísticas.
                  E paralelamente, pretende incutir valores, como a solidariedade e de entreajuda.
                </p>
                <p>
                  Este ano, o “CompartilhArte” terá uma edição especial , com o tema “ As Caricaturas dos Afectos” e visa selecionar uma ilustração
                  original que será a mascote do projeto “CompartilhArte”22.
                </p>
                <p>
                  Público – Alvo: Pré-Escolar, 1º Ciclo e 2º Ciclo.
                </p>
                <p>
                  Contamos com a sua inscrição até 31 de janeiro de 2022?
                </p>
                <p>
                  <b>Testemunhos</b>
                </p>
                <p>
                  “É uma grande honra para mim poder partilhar convosco o grande gesto de solidariedade que tiveram para com a nossa aluna (…).
                  Precisava de uns óculos novos e não tinha os meios para os adquirir. Este vosso gesto contribuiu para devolver
                  a qualidade de vida que ela
                  tanto ambicionava.” Professora Solidária Ana Paula, Agrupamento de Escolas de Olaias
                </p>
                <p>
                  “Gostaria de agradecer à instituição pela boa ação dos óculos, pois no momento estou passando dificuldades financeiras.
                  Estou muito feliz e fico muito grata por ter conseguido finalmente os óculos, pois estava com muita dor de cabeça e eles já
                  não me serviam mais. Gratidão ”
                  “Maria”
                </p>
              </div>
              <img className="right-side" src={Compartilharte} alt="Leilão Solidário" />
            </div>

            <div>
              <img className="left-side" src={Historias} alt="Leilão Solidário" />
              <div>
                <h2>Histórias da Ajudaris</h2>
                <p>
                  2022 – Tema ” A Água”!
                </p>
                <p>
                  Desde 2009 a promover a leitura, a escrita, a arte e a solidariedade, em parceria com os Estabelecimentos de Ensino Solidários!
                </p>
                <p>
                  Em sala de aula, na Biblioteca, em Família, juntos vamos criar textos de qualquer género literário para dar visibilidade às boas práticas
                  dos Educadores e Professores. Vamos proporcionar o poder da autoria aos pequenos grandes autores e, paralelamente ajudar crianças a
                  necessitar da nossa solidariedade. Vamos viajar pelo mundo dos textos?
                </p>
                <p>
                  Inscrições 22
                  <b>
                    <a className="special-link" href="https://docs.google.com/forms/d/e/1FAIpQLSddRhYkWlvPoiw1DpWQk78EnUxSs8LzYd_a95eHVHSETvVbYg/viewform" target="_blank" rel="noreferrer">
                      {' '}aqui!
                    </a>
                  </b>
                </p>
                <p>
                  Regulamento 22
                  <b>
                    <a className="special-link" href="https://drive.google.com/file/d/1XAQQz1cQPMf7abypJhI1Tdmkl6PHaAnk/view" target="_blank" rel="noreferrer">
                      {' '}aqui!
                    </a>
                  </b>
                </p>
                <p>
                  Os pequenos grandes autores sob a orientação de professores e educadores solidários, tornam-se verdadeiros autores de histórias de encantar,
                  sobre temas como a solidariedade, os afetos, a cidadania, o ambiente, os valores, entre outros, de especial relevância.
                  Cada história conta com um ilustrador solidário que colhe inspiração na história que lhe for atribuída, dando cor e vida às suas personagens e cenários.
                  O resultado é uma obra imperdível que vai querer ler!
                </p>
                <p>
                  <b>Sou Professor/Educador e gosto de escrita criativa!</b>
                </p>
                <p>
                  <b>Quero tornar o meu filho ou filha, num escritor ou escritora!</b>
                </p>
                <p>
                  <b>Quero tornar-me um/a ilustrador/a solidário/a!</b>
                </p>
                <p>
                  Aguardamos o seu email para historiasdaajudaris@ajudaris.org para saber como pode abraçar este projeto!
                </p>
                <p>
                  Todos podem participar neste projeto de afetos!
                </p>
                <p>
                  Pequenos gestos, Grandes corações!
                </p>
              </div>
            </div>

            <div>
              <div>
                <h2>Histórias da Ajudaris em Voz Alta</h2>
                <p>
                  A atividade “Histórias da Ajudaris em Voz Alta” desafia falantes da língua portuguesa no mundo a ler/interpretar histórias escritas por
                  jovens autores que integram os livros “Histórias da Ajudaris”.
                </p>
                <p>
                  Ler, também pode ser divertido!
                </p>
              </div>
              <img className="right-side" src={VozAlta} alt="Leilão Solidário" />
            </div>

            <Row>
              <Col xs={24} md={12}>
                <h2>S.O.S Fome</h2>
                <p>
                  O projeto S.O.S. Fome apoia a nível alimentar e outras necessidades básicas
                  crianças e suas famílias que vivem em situação de pobreza extrema, encaminhadas na sua
                  maioria por Escolas.  Após o encaminhamento, é realizada uma visita ao domicílio e se for
                  constatada a insuficiência económico-social, fica abrangida pelo referido projeto.
                  Além do apoio alimentar, doamos outros bens essenciais a uma vida condigna. Paralelamente,
                  são desenvolvidas as competências das famílias com o objetivo de as tornar autónomas.
                </p>
              </Col>
              <Col xs={24} md={12}>
                <h2>Música para Todos</h2>
                <p>
                  Música para Todos/Desporto para Todos – Destinada a todas as faixas etárias,
                  em que as crianças do primeiro escalão do IRS não pagam.‍
                  <br />
                  <b>Público-alvo</b>: <i>Todos os interessados.</i>
                </p>
              </Col>
            </Row>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
