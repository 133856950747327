import React, { useEffect, useState } from 'react';
import data from '../assets/json/auctions21.json';
import axios from 'axios';
import 'antd/dist/antd.css';
import { Select, message, Carousel, Row, Col, Button, InputNumber } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

import Login from './Login';
import useToken from '../useToken';
import '../assets/scss/Backoffice.scss'

const { Option } = Select

function Backoffice() {
    const [auctions, setAuctions] = useState([]);
    const [auction, setAuction] = useState(null);
    const [isDefined, setIsDefined] = useState(false);
    const [price, setPrice] = useState(0);
    const [prices, setPrices] = useState([]);

    const { token, setToken } = useToken();


    function fetchAuctionPrices() {
        axios.get("https://ajudaris-api.herokuapp.com/api/auctions")
            .then(data => {
                const prices = data.data.map(product => {
                    return {
                        key: +product.name,
                        price: product.price
                    }
                })
                setPrices(prices);
            })
            .catch(error => {
                console.warn(error);
            })
    }

    useEffect(() => {
        fetchAuctionPrices();
    }, []);


    useEffect(() => {
        async function fetchAuctions() {
            const auctions = await Promise.all(
                data.auctions
                    .sort((auction1, auction2) => auction1.sellerName.localeCompare(auction2.sellerName))
                    .map(async (auction) => {
                        let bioText = "";
                        let sellerBioText = ""
                        if (auction.productBio) {
                            await axios.get(`./auctions/${auction.sellerName}/${auction.productBio}`)
                                .then(bio => {
                                    bioText = bio.data;
                                })
                                .catch((error) => console.warn(`${auction.sellerName} + ${error}`));
                        }

                        if (auction.sellerBio) {
                            await axios.get(`./auctions/${auction.sellerName}/${auction.sellerBio}`)
                                .then(bio => {
                                    sellerBioText = bio.data;
                                })
                                .catch((error) => console.warn(`${auction.sellerName} + ${error}`));;
                        }

                        return {
                            key: auction.key,
                            productImages: auction.productImages,
                            productName: auction.productName,
                            productBio: bioText,
                            sellerImage: auction.sellerImage,
                            sellerName: auction.sellerName,
                            sellerBio: sellerBioText,
                            price: auction.price,
                            link: auction.link
                        }
                    }));
            setAuctions(auctions);
        }
        fetchAuctions();
    }, []);

    if (!token) {
        return <Login setToken={setToken} />
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    const displayObjectInfo = () => {
        if (isDefined) {
            return (
                <div>
                    <Carousel autoplay>
                        {
                            auction.productImages.map((image, index) => (
                                <div key={index}>
                                    <img className="product-image" src={`./auctions/${auction.sellerName}/${image}`} alt={auction.productName} />
                                </div>
                            ))
                        }
                    </Carousel>
                    <p className="product-bio">{auction.productName}</p>
                    <Row className="seller-info">
                        <Col span={8}>
                            <img className="seller-image" src={`./auctions/${auction.sellerName}/${auction.sellerImage}`} alt={auction.sellerName}
                                onError={(e) => { e.target.src = `./auctions/profile-picture-error.jpg` }} />
                            <p><b>{auction.sellerName}</b></p>
                        </Col>
                        <Col className="pricing" span={8}>
                            <p>
                                <b>Preço Base</b>
                                <br />
                                €{auction.price.toFixed(2)}
                            </p>
                            <p>
                                <b>Preço da Licitação</b>
                                <br />
                                €{prices.find(price => price.key === auction.key).price.toFixed(2)}
                            </p>
                        </Col>
                        <Col className="auction-btn-wrapper" span={8}>
                            <Button className="auction-btn" type="primary" size="large" href="https://forms.gle/GtiHoo8z5FTcqEWJ6" target="_blank">
                                Leilão <FontAwesomeIcon className="money-check" icon={faWallet} />
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    async function handleChange(e) {
        const auctionFound = await auctions.find(auction => auction.key === e)
        const auctionPrice = prices.find(price => price.key === e);
        setAuction(auctionFound);
        setPrice(auctionPrice.price);
        setIsDefined(true);
    }

    function updatePrice(e) {
        setPrice(e);
    }

    const onFinish = () => {

        const updatedAuction = {
            name: auction.key,
            price: price
        }
        axios.patch(`https://ajudaris-api.herokuapp.com/api/auctions`,
            updatedAuction,
            config
        )
            .then((response) => {
                message.success('Leilão atualizado com sucesso!');
                fetchAuctionPrices();
                console.log(response);
            }, (error) => {
                message.error('Ocorreu um erro a atualizar o leilão!');
                console.log(error);
            });
    };

    return (
        <div id="backoffice">
            {displayObjectInfo()}
            <div className="selector">
                <Select
                    style={{ width: 500 }}
                    placeholder="Selecione leilão"
                    onChange={handleChange}>
                    {
                        auctions.map(auction => (
                            <Option key={auction.key} value={auction.key}>
                                {auction.productName} - {auction.sellerName}
                            </Option>
                        ))
                    }
                </Select>
                <label><b>Preço</b></label>
                <InputNumber id="input-preco" onChange={updatePrice} value={price ? price : 0} min={auction ? auction.price : 0} />
                <Button type="primary" onClick={onFinish}>Guardar</Button>
            </div>
        </div>
    )
}

export default Backoffice;