import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../assets/scss/Login.scss';

function Login({ setToken }) {

    useEffect(() => {
        axios.head("https://ajudaris-api.herokuapp.com/status")
            .then(response => {
                console.log("Server is running OK!");
            })
            .catch(error => {
                console.warn("Error in server: ", error);
            })
    }, [])

    const [formLogin] = Form.useForm();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    async function requestToken(credentials) {
        axios.post("https://ajudaris-api.herokuapp.com/api/auth/signin",
            {
                username: credentials.username,
                password: credentials.password
            })
            .then(response => {
                if (response.data.token) {
                    setToken(response.data.token);
                }
            })
            .catch((error) => {
                message.error("Credenciais erradas!")
                formLogin.resetFields();
                console.warn(error);
            })
    }

    const onSubmit = async () => {
        await requestToken(
            {
                username,
                password
            }
        );
    }

    return (
        <div id="login">
            <Form
                form={formLogin}
                name="login-form"
                className="login-form"
                onFinish={onSubmit}
            >
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Introduza o seu nome de utilizador!' }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Nome Utilizador"
                        onChange={e => setUsername(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Introduza a sua palavra-passe!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Palavra-Passe"
                        onChange={e => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item className="login-form-wrapper">
                    <Button type="primary" htmlType="submit">
                        Entrar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;