import '../assets/scss/Header.scss'
import Ajudaris from '../assets/img/ajudaris-logo.png';
import { Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const menu = (
    <Menu>
        <Menu.Item>
            <a href="#leilao">Leilão Solidário</a>
        </Menu.Item>
        <Menu.Item>
            <a href="#sobre-nos">Sobre Nós</a>
        </Menu.Item>
        <Menu.Item>
            <a href="#como-ajudar">Como Ajudar</a>
        </Menu.Item>
        <Menu.Item>
            <a href="#projetos">Projetos</a>
        </Menu.Item>
        <Menu.Item>
            <a href="#footer">Contactos</a>
        </Menu.Item>
    </Menu>
);

function Header() {
    return (
        <div id="header-wrapper">
            <div id="header">
                <a href="/">
                    <img id="ajudaris-logo" src={Ajudaris} alt="Ajudaris" />
                </a>
                <div id="navbar">
                    {
                        window.location.pathname === '/' &&
                        <div>
                            <a href="#leilao">Leilão Solidário</a>
                            <a href="#sobre-nos">Sobre Nós</a>
                            <a href="#como-ajudar">Como Ajudar</a>
                            <a href="#projetos">Projetos</a>
                            <a href="#footer">Contactos</a>
                        </div>
                    }
                    {
                        window.location.pathname === '/leilao' &&
                        <div>
                            <a href="/">Voltar</a>
                        </div>
                    }
                </div>
                <div id="mobile-navbar">
                    {
                        window.location.pathname === '/' &&
                        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                            <FontAwesomeIcon id="mobile-menu-btn" icon={faBars} />
                        </Dropdown>
                    }
                    {
                        window.location.pathname === '/leilao' &&
                        <div>
                            <a href="/">Voltar</a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Header;